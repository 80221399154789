<template>
  <div class="custom-table" :class="{ 'custom-table--no-header': !header }">
    <a-table
      v-if="data.length > 0"
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      :rowKey="rowKey"
      :rowClassName="rowClassName"
      @change="handleTableChange"
    >
      <template
        v-for="(c, index) in columns"
        :key="index"
        v-slot:[c.slots.customRender]="slots"
      >
        <slot
          v-if="c.slots"
          :name="slots.column.key"
          :column="slots.text"
          :record="slots.record || {}"
        ></slot>
      </template>
      <template #footer>
        <slot name="footer"></slot>
      </template>
      <template #filterIcon="filtered">
        <span v-if="filtered">test</span>
        <span v-else>test 2</span>
      </template>
    </a-table>
    <a-table
      :columns="columns"
      :dataSource="data"
      :locale="{ emptyText: 'Aucune(s) donnée(s) pour l\'instant' }"
      v-else
    ></a-table>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Table from 'ant-design-vue/lib/table'

@Options({
  name: 'TableComponent',
  components: {
    ATable: Table,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: [Boolean, Object],
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    rowClassName: {
      type: Function,
      default: () => '',
    },
    rowKey: {
      type: [Function, String],
      default: 'id',
    },
  },
  emits: ['change'],
  methods: {
    handleTableChange(pag: any, filters: any, sorter: any) {
      this.$emit('change', sorter)
    },
  },
})
export default class TableComponent extends Vue {}
</script>

<style lang="scss">
.custom-table {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
  }
  .ant-table-footer {
    padding: 0;
    background-color: transparent;
  }

  .ant-table {
    background-color: transparent;
  }

  tbody:before {
    content: '-';
    display: block;
    line-height: 8px;
    color: transparent;
  }
  .ant-table-thead > tr:first-child th {
    color: #4b45b2;
    font-weight: normal;
    padding: 8px 20px;
    background-color: rgba(255, 127, 137, 0.3) !important;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .ant-table-column-sorters {
      &:before {
        display: none;
      }
    }
  }
  tr {
    &:hover {
      td {
        background-color: #e8ecff !important;
      }
    }
    td {
      background-color: #f4f5fa !important;
      color: #4b45b2;
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .blue-column td {
    background-color: rgba(156, 201, 245, 0.3) !important;
  }
  .ant-table-thead > tr > th .ant-table-column-sorter {
    display: inline-block;
  }
  .ant-table-tbody > tr > td {
    padding: 13px 20px 14px;
  }
  &--no-header {
    thead {
      display: none;
    }

    .ant-table-tbody {
      &:before {
        display: none;
      }
    }
    table {
      border-spacing: 0;
    }
    tr td {
      background-color: rgba(76, 73, 175, 0.2) !important;
    }
  }
  tr.disable-row {
    td {
      background-color: #ebf3f7;
      &:not(:last-child) {
        span,
        a {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
