
import { Options, Vue } from 'vue-class-component'
import Table from 'ant-design-vue/lib/table'

@Options({
  name: 'TableComponent',
  components: {
    ATable: Table,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: [Boolean, Object],
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    rowClassName: {
      type: Function,
      default: () => '',
    },
    rowKey: {
      type: [Function, String],
      default: 'id',
    },
  },
  emits: ['change'],
  methods: {
    handleTableChange(pag: any, filters: any, sorter: any) {
      this.$emit('change', sorter)
    },
  },
})
export default class TableComponent extends Vue {}
