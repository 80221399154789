
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
})
export default class CardComponent extends Vue {}
